/* Style The Dropdown Button */
.dropbtn {
  background-color: whitesmoke;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
  width: 100%;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: absolute;
  display: inline-block;
  right: 3%;
  width: 15%;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: whitesmoke;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content button {
  color: black;
  background-color: whitesmoke;
  padding: 12px 16px;
  display: block;
  width: 100%;
  border: none;
}

/* Change color of dropdown links on hover */
.dropdown-content button:hover {
  background-color: black;
  color: whitesmoke;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #f2ebeb;
  color: black;
}
