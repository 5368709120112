/* .pusher {
  width: 80%;
  position: absolute;
} */

.my-footer {
  /* left: 0;
  bottom: 0; */
  width: 100%;
  background-color: aliceblue;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
}
